<template>
  <v-sheet v-bind="propsCompSheet" data-cy="documents-edit">
    <template v-if="objectId">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <!-- <span class="reqMark">*</span> -->
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.name')"
              v-model="editName"
              :error-messages="editNameErrors"
              @input="$v.editName.$touch()"
              @blur="$v.editName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>
          <div class="formField">
            <!-- <span class="reqMark">*</span> -->
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.description')"
              v-model="editDescription"
              :error-messages="editDescriptionErrors"
              @input="$v.editDescription.$touch()"
              @blur="$v.editDescription.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <span class="reqMark noSpace">*</span>
            <v-radio-group
              :label="$t('documents.fileClass')"
              mandatory
              v-model="editFileClass"
              @input="$v.editFileClass.$touch()"
              @blur="$v.editFileClass.$touch()"
            >
              <v-radio
                :label="$t('documents.fileClassNormal')"
                value="normal"
              ></v-radio>
              <v-radio
                :label="$t('documents.fileClassHtml')"
                value="html"
              ></v-radio>
              <v-radio
                :label="$t('documents.fileClassLink')"
                value="link"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="formField">
            <span v-if="fileRequired" class="reqMark">*</span>
            <v-file-input
              :disabled="fileInputDisabled"
              v-bind="propsFormFields"
              :label="$t('documents.formFileUpload')"
              show-size
              v-model="editUploads"
            ></v-file-input>
          </div>
          <div v-if="editFileClass == 'link'" class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('documents.linkAddress')"
              v-model="editLinkAddress"
              :error-messages="editLinkAddressErrors"
              @input="$v.editLinkAddress.$touch()"
              @blur="$v.editLinkAddress.$touch()"
              type="text"
            ></v-text-field>
          </div>

          <div class="formField">
            <v-checkbox
              v-bind="propsFormFields"
              :label="$t('documents.private')"
              v-model="editPrivate"
            ></v-checkbox>
          </div>
          <div class="formField">
            <v-autocomplete
              v-bind="propsFormFields"
              v-model="editOwnerId"
              :items="users"
              item-value="id"
              :label="$t('users.owner')"
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.name }} {{ item.surnames }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.name }} {{ item.surnames }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </v-form>
      </div>
      <div class="d-flex">
        <v-btn
          v-if="
            document.fileType &&
            (authCanByUserId('deleteOwn.documents', document.ownerId) ||
              authCan('deleteOthers.documents'))
          "
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          :to="{ name: 'documents-delete', params: { id: objectId } }"
          data-cy="button-delete"
          >{{ $t("common.delete") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-save"
          >{{ $t("common.save") }}</v-btn
        >
      </div>
    </template>
    <v-alert v-else type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";
import axios from "axios";
import { required, maxLength, url, requiredIf } from "vuelidate/lib/validators";

const optionalUrl = (value, vm) => {
  if (!vm.linkAddressRequired) {
    return true;
  }
  return url(value);
};

export default {
  name: "DocumentEdit",
  mixins: [ComponentStatus, Permissions],
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    firstFormClick: true,
    changed: false,
    // Edit proxy form data
    editDocumentId: "",
    editName: "",
    editFileName: "",
    editDescription: "",
    editFileClass: "normal",
    editLinkAddress: "https://",
    editPrivate: false,
    editFolderId: null,
    editOwnerId: null,
    editUploads: [],

    documentBase64: "",
  }),
  validations: {
    editName: {
      maxLength: maxLength(1000),
    },
    editDescription: {},
    editUploads: {
      required: requiredIf("fileRequired"),
    },
    editLinkAddress: {
      required: requiredIf("linkAddressRequired"),
      url: optionalUrl,
    },
    editFileClass: { required },
    editPrivate: {},
  },
  computed: {
    fileRequired() {
      if (this.editFileClass !== "link") {
        if (this.document.fileClass !== "link") {
          return false;
        }
        return true;
      }
      return false;
    },
    linkAddressRequired() {
      if (this.editFileClass === "link") {
        if (this.document.fileClass === "link") {
          return false;
        }
        return true;
      }
      return false;
    },
    editUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    users() {
      return this.$store.getters["users/readAll"];
    },
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    fileInputDisabled() {
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    document() {
      return this.$store.getters["documents/readById"](this.objectId);
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      return errors;
    },
    editFileClassErrors() {
      const errors = [];
      if (!this.$v.editFileClass.$dirty) return errors;
      if (!this.$v.editFileClass.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editLinkAddressErrors() {
      const errors = [];
      if (!this.$v.editLinkAddress.$dirty) return errors;
      if (!this.$v.editLinkAddress.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editLinkAddress.url) {
        errors.push(this.$t("common.errors.url"));
      }
      return errors;
    },
  },
  watch: {
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editDocumentId = this.objectId;
          this.$store
            .dispatch("documents/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    document: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {
    this.$store.dispatch("users/readAll").then((/* result */) => {});
  },
  methods: {
    // TODO: think if there is a better way to handle the promise for the
    // proper variable assignation.
    documentDecoded() {
      if (this.editFileClass === "link" && this.documentBase64) {
        this.editLinkAddress = atob(this.documentBase64);
      } else if (this.editFileClass === "link") {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/documents/${this.objectId}/base64`,
        }).then((result) => {
          this.documentBase64 = result.data;
          this.editLinkAddress = atob(this.documentBase64);
        });
      } else {
        this.editLinkAddress = "";
      }
    },
    inputChanged() {
      this.changed = true;
    },
    refreshFormData(value) {
      let newValue = this.document;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        this.editName = newValue.name;
        this.editFileName = newValue.fileName;
        this.editDescription = newValue.description;
        this.editFileClass = newValue.fileClass;
        this.editPrivate = newValue.private;
        this.editFolderId = newValue.folderId;
        this.editOwnerId = newValue.ownerId;

        if (this.editFileClass === "link") {
          this.documentDecoded();
        }
      }
    },
    clickUpdate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        if (
          this.editUploads.length > 0 ||
          (this.editFileClass === "link" && this.editLinkAddress !== "https://")
        ) {
          this.sendUpdateWithFile();
        } else {
          this.sendUpdate();
        }
      }
    },
    sendUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editDocumentId,
        name: this.editName,
        fileName: this.editFileName,
        fileClass: this.editFileClass,
        private: this.editPrivate,
        folderId: this.editFolderId,
        ownerId: this.editOwnerId,
      };
      if (this.editDescription) {
        payload.description = this.editDescription;
      }
      this.$store
        .dispatch("documents/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
    sendUpdateWithFile() {
      this.setStatusUpdating();
      const reader = new FileReader();
      reader.onload = (event) => {
        let file = event.target.result;

        let payload = {
          id: this.editDocumentId,
          name: this.editName,
          fileName: this.editUploads.name,
          fileClass: this.editFileClass,
          fileType: this.editUploads.type,
          file: file,
          description: this.editDescription,
          private: this.editPrivate,
          folderId: this.folderId,
          ownerId: this.editOwnerId,
        };
        if (
          this.editFileClass === "link" &&
          this.editLinkAddress !== "https://"
        ) {
          payload.fileName = "link.txt";
          payload.fileType = "text/plain";
        }
        this.$store
          .dispatch("documents/update", payload)
          .then((result) => {
            this.setStatusUpdateSuccess();
            this.$store.commit("status/showSuccess");
            this.changed = false;
            // this.refreshFormData();
            if (result) {
              this.$router.push({
                name: "documents-show",
                params: {
                  id: result,
                },
              });
            } else {
              this.$router.push({
                name: "documents-index",
              });
            }
          })
          .catch((/* error */) => {
            this.setStatusUpdateError();
            this.$store.commit("status/showError");
          });
      };
      if (
        this.editFileClass === "link" &&
        this.editLinkAddress !== "https://"
      ) {
        const tempFile = new File([this.editLinkAddress], "link.txt", {
          type: "text/plain",
        });
        reader.readAsDataURL(tempFile);
      } else {
        reader.readAsDataURL(this.editUploads);
      }
    },
  },
};
</script>
